
import React, {useState, useEffect} from "react";
import "./redeemModal.css";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {  setLoader, setModalMessage } from "../../../redux/reducer";
import { POSTUseUtilitieService, PUTUseConfirmService } from "../../../services/UtilitieService";
import { claim } from "../../../helpers/web3/claim";
import { setApproval } from "../../../helpers/web3/setApproval";
import { useNavigate } from "react-router-dom";
import { GETGasPriceService } from "../../../services/GasPrice";

export const RedeemModal = ({ID, imageUrl, price, name, color, isDigital, rejectButton, utilityID}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [gasPrice, setgasPrice] = useState();
    const [disableConfirm, setdisableConfirm] = useState(false);
    const address = useSelector(state => state.market.address)

    useEffect(() => {
      const init = async () => {
        GETGasPriceService().then(data => {
          setgasPrice(`${data.aux.slice(0, 5)} gwei`);
        })
      };
  
      init();
    }, []);
  
  
  const confirmHandler = async () => {
    try {
      if(disableConfirm) return;
      
      window.onbeforeunload = function() {
        return "Would you like to close the window? If you close before the transaction finish we wont be able to redirect to the market to obtain your product";
      };

      dispatch(setLoader(true))
      setdisableConfirm(true)

      await setApproval()
      var response = await POSTUseUtilitieService(utilityID);
      await claim(utilityID)
      response = await PUTUseConfirmService(response.activityID);

      // dispatch(setModalMessage(texts.redeem_utility(name)))
      window.location = response.redeemUrl.replace("{ADDRESS}", address)
      navigate("/")
    } catch (er) {
     dispatch(setModalMessage(er?.message)) 
    }
    window.onbeforeunload = function() {
      return
    };
    dispatch(setLoader(false))
    closeModal()

  }
  const closeModal = () => {
    rejectButton()
  }

  const Buttons  = () => {
    return <div className="redeemModal-ButtonWrapper">
        <button className={`redeemModal-cancel ${disableConfirm ? "redeemModal-disabled" : ""}`} onClick={closeModal}>Reject</button>
        <button className={`redeemModal-button ${disableConfirm ? "redeemModal-disabled" : ""}`} onClick={confirmHandler}>Confirm</button>
      </div>
    }

  return (
   (
      createPortal(
      <div className="redeemModal-Wrapper">
        <div>
          <div className="redeemModal-Modal">
            <div className="redeemModal-content">
              <img className="redeemModal-image" src={imageUrl} alt="item"/>
              <div className="redeemModal-info">
                <h3>{name}</h3>
                <div>{isDigital ? "Digital" :  "Physical"} utility</div>
                <div className="redeemModal-Footer">Estimated gas {gasPrice ?? "..."}</div>
              </div>
            </div>
            {!isDigital && <div style={{textAlign: "center"}}>After confirmation we will redirect you to the market site</div>}
          </div>
          <Buttons />
        </div>
      </div>, document.getElementById("modal"))
    )
  );
}

