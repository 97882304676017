import React, { useEffect, useState } from 'react'
import "./soap.css";
import { ModalAliens } from '../../../components/ModalAliens';
import defaultAlien from '../../../assets/images/animations/soap/alienEmpty.png';
import soapUrl from '../../../assets/images/animations/soap/soap.png';
import { GETAliensService } from '../../../services/AliensService';
import { useDispatch } from 'react-redux';
import { setLoader, setModalMessage } from '../../../redux/reducer';
import { POSTUseUtilitieService, PUTUseConfirmService, PUTUseRejectService } from '../../../services/UtilitieService';
import { getBalance } from '../../../helpers/web3/getBalance';
import { useNavigate } from 'react-router-dom';
import { claim } from '../../../helpers/web3/claim';
import { texts } from '../../../constants/texts';
import { Polaroid } from '../../polaroid';
import { utilityIDs } from '../../../constants/Web3Constants';
import { setApproval } from '../../../helpers/web3/setApproval';


export const Soap = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [working, setworking] = useState(false)
  const [done, setdone] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [actualAlien, setActualAlien] = useState({
    id:null,
    url:defaultAlien
  });
  const [aliens, setAliens] = useState([]);
  const [imgResponse, setimgResponse] = useState("")

  useEffect(() => {
    const init = async () => {
      try {
        dispatch(setLoader(true));
        let res = await getBalance(utilityIDs.soap);

        if (res <= 0) {
          navigate("/");
          dispatch(setModalMessage("Sorry! you need to buy a eyedrop to use in this site"));
        }

        res = await GETAliensService("soap");
        setAliens(res.data);
      } catch (er) {
        dispatch(setModalMessage(er?.message))
      }
      dispatch(setLoader(false));
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

const buttonHandler = async() => {
  var responseUseUtility;
  try{
    if(working) return;
    if(!actualAlien.id){
      dispatch(setModalMessage(texts.alien_not_selected))
    return;}
    window.onbeforeunload = function() {
      return "Would you like to close the window? If you close before the transaction finish the utility wont be apply into your alien";
    };
    setworking(true);
    await setApproval();
    responseUseUtility = await POSTUseUtilitieService(utilityIDs.soap, actualAlien.id);
    await claim(utilityIDs.soap)
    let responseConfirm = await PUTUseConfirmService(responseUseUtility.activityID)
    setimgResponse(responseConfirm.imageUrl)

    setdone(true)

  }catch(er){
    setworking(false)
    responseUseUtility?.activityID && await PUTUseRejectService(responseUseUtility.activityID)
    dispatch(setModalMessage(er?.message))

  }
  window.onbeforeunload = function() {return};
}

const openAlienModalHandler = () => {
    if(working || done) return;
    setIsOpen(true)
}
  return (
    <>
        {imgResponse && <Polaroid text="CLEANED" imgSrc={imgResponse} setimgSrc={setimgResponse}/>}
        <div className={`soap-animationWrapper ${(working && !done) ? "soap-working" : ""}`} >
            <div className='soap-item'>
              <img src={soapUrl} width="80%" alt="" />
            </div>
            <div onClick={openAlienModalHandler} className={`soap-alien-listener ${working ? "soap-alien-listener-pressed" : ""}`} >
            </div> 
            <div  className={`soap-alien ${(working && !done) ? "soap-alien-washing" : ""}`}>
              <img src={actualAlien.url} width="100%" alt="" />
            </div> 
            <div className={`soap-button ${working ? "soap-pressed" : ""}`} onClick={buttonHandler}></div>
        </div>
        <ModalAliens open={isOpen} onClose={() => setIsOpen(false)} aliens={aliens} setActualAlien={setActualAlien} />
    </>
  )
}
